
import ApiService from "@/core/services/ApiService"

import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import RevenueTypeComponent from "@/components/finance/definitions/revenue-type.vue"
import { Tag, TagType } from "@/core/models/Tag"
import { CirclePlus } from "@element-plus/icons-vue"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "revenue-type-list",
  components: { RevenueTypeComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const newRevenueType = ref(false)

    const RevenueTypeList = ref<Tag[]>([] as Tag[])

    const activeRevenueTypeId = ref<string | undefined>()

    const activeParentId = ref<string>()
    const activeParentName = ref<string>("")

    const treeProps = {
      id: "id",
      parentId: "parentId",
      children: "childs",
    }

    watch(
      () => props.add,
      newValue => {
        newRevenueType.value = true == newValue
      }
    )

    watch(
      () => route.params.revenue_type_id,
      newValue => {
        activeRevenueTypeId.value = newValue as string
      }
    )

    const getRevenueTypeList = async () => {
      var config = {
        params: {
          tagType: TagType.RevenueType,
        },
      }
      const { data } = await ApiService.query("tag/childs", config)
      RevenueTypeList.value = data
    }

    async function removeRevenueType(revenueTypeId) {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("tag/remove/" + revenueTypeId)
        await getRevenueTypeList()
      }
    }

    function handleRevenueTypeDrawerClose() {
      activeParentId.value = undefined
      activeParentName.value = ""
      router.push({ name: "revenue-type-list" })
    }

    const OnClickChild = (parentId, parentName) => {
      activeParentId.value = parentId as string
      activeParentName.value = parentName
      router.push({ name: "revenue-type-add", params: { activeParentId: parentId } })
    }

    onMounted(() => {
      getRevenueTypeList()
      activeRevenueTypeId.value = route.params.revenue_type_id as string
      newRevenueType.value = props.add
    })

    return {
      Edit,
      CirclePlus,
      removeRevenueType,
      getRevenueTypeList,
      activeRevenueTypeId,
      newRevenueType,
      handleRevenueTypeDrawerClose,
      RevenueTypeList,
      activeParentId,
      OnClickChild,
      treeProps,
      activeParentName,
    }
  },
})
